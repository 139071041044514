import { Component } from 'react'
import config from '../../../../config'
import { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { uploadChargebackFile } from '../../../services/APIClient'

class ChargebackUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
    }
  }

  onSubmit = (event) => {
    event.preventDefault()
    uploadChargebackFile(this.state.file)
  }

  onChange = (event) => {
    this.setState({ file: event.target.files[0] })
  }

  render() {
    const urlUploadFile = `${config.TRANSACTION_BASE_URL}/chargeback`

    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>Upload the chargeback csv to update our transactions</label>
            </div>
            <form action={urlUploadFile} method="post" encType="multipart/form-data" onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-md-6 col-4">
                  <input
                    type="file"
                    className="form-control-file"
                    id="file"
                    name="file"
                    accept=".csv"
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-md-6 col-4">
                  <button type="submit" className="btn btn-info">
                    Upload file
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ChargebackUpload
