import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { createCredentials, findAllIntermediariesByAccountId } from '../../../../services/APIClient'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'
import { CircularProgress } from '@mui/material'

const CredentialsCreator = ({ accountId, softDescriptor }) => {
  const [intermediaries, setIntermediaries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    clientId: '',
    secretKey: '',
  })
  const [selectedIntermediary, setSelectedIntermediary] = useState(null)

  useEffect(() => {
    findAllIntermediariesByAccountId(accountId).then((intermediariesByAccountId) => {
      setIntermediaries(
        intermediariesByAccountId.result.map((intermediary) => {
          const obj = {
            value: intermediary.provider.id,
            label: intermediary.provider.softDescriptor,
          }
          return obj
        }),
      )
    })
  }, [accountId])

  const handleCreateCredentials = () => {
    setIsLoading(true)
    createCredentials(accountId, selectedIntermediary)
      .then((resp) => {
        setCredentials({
          clientId: resp.clientId,
          secretKey: resp.secretKey,
        })
      })
      .catch(() => alert('Something went wrong. Please, contact system administrator.'))
      .finally(() => setIsLoading(false))
  }

  return (
    <Fragment>
      <div className="card-modal-report">
        <div style={{ paddingLeft: '30px' }}>
          <div>
            <label>All receivables that we will receive in the selected period</label>
          </div>
          <div className="row">
            <div className="col-md-6 col-4">
              <input type="text" value={softDescriptor} readOnly style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="col-md-6 col-4">
              <Select
                defaultValue={[intermediaries[0]]}
                name="accountType"
                id="accountType"
                required
                onChange={(event) => setSelectedIntermediary(event.value)}
                options={intermediaries}
                placeholder="Intermediary"
                classNamePrefix="Intermediary"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-4" style={{ paddingTop: '20px' }}>
              {isLoading ? (
                <button className="buttonSendDate">
                  <CircularProgress size={30} />
                </button>
              ) : (
                <button className="buttonSendDate" onClick={handleCreateCredentials}>
                  Create credentials
                </button>
              )}
            </div>
          </div>
          <div className="row2" style={{ marginTop: '50px' }}>
            <BTable striped bordered hover>
              <tbody>
                <tr>
                  <td>AccountId</td>
                  <td>{accountId}</td>
                </tr>
                <tr>
                  <td>clientId</td>
                  <td>{credentials.clientId}</td>
                </tr>
                <tr>
                  <td>secretKey</td>
                  <td>{credentials.secretKey}</td>
                </tr>
              </tbody>
            </BTable>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CredentialsCreator
