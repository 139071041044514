import { toast } from 'react-toastify'
import history from '../../history.js'
import { saveStreamCSV } from './CsvDownloader'

export function defaultUserErrorCallback(response) {
  response.text().then((content) => {
    try {
      createToast(`An error occured: ${JSON.parse(content)?.errorMessage}`)
    } catch {
      createToast(`An error occured: ${content}`)
    }
  })
}

export function defaultUnauthorizedCallback(response) {
  createToast(`The token is expired, you will be redirected to the login page!`)
  history.push({ pathname: '/login/signin' })
  window.location.reload()
}

export function defaultForbiddenCallback(response) {
  createToast(`User doesn't have role to operation`)
}

export function defaultServerErrorCallback(response) {
  createToast(`Please contact SysAdmin`)
}

export function createToastAfterFileUpload(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    createToast(`Uploaded successfully :D!`)
  }
}

export function accountRegisterCallback(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    response.json().then((resp) => {
      createToast(`Account successfully registered :D!`)
      history.push({ pathname: `/account/${resp.id}` })
      window.location.reload()
    })
  }
}

export function accountUpdateCallback(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    response.json().then((resp) => {
      createToast(`Account successfully updated :D!`)
      history.push({ pathname: `/account/${resp.id}` })
      window.location.reload()
    })
  }
}

export function accountFeesUpdateCallback(response, accountId) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    response.json().then((_) => {
      createToast(`Account successfully updated :D!`)
      history.push({ pathname: `/account/${accountId}` })
      window.location.reload()
    })
  }
}

export function csvDownloadCallback(response, fileName) {
  response.text().then((content) => saveStreamCSV(fileName, content))
}

export function transferNetCreditCallback() {
  createToast(`Transfer requested. After a few minutes, check your bank statement!`)
}

export function createIntermediaryCallback() {
  createToast('Intermediary successfully registered :D!')
}

export function approveSettlementCallback(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    createToast(`Settlement in batch successfully executed :D!`)
    window.location.reload()
  }
}

export function executeFundSentSettlementCallback(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    createToast(`Settlement in batch successfully fund sent :D!`)
    window.location.reload()
  }
}

export function executeWithdrawnSettlementCallback(response) {
  if (response.status !== 200) {
    createToast(`Please contact SysAdmin`)
  } else {
    createToast(`Settlement in batch successfully withdrawn :D!`)
    window.location.reload()
  }
}

export function providerConfigurationRegisterCallback(response) {
  if (response.status !== 200) {
    response
      .json()
      .then((body) => {
        const causes = body.errorCauses?.map((item) => item.cause).join(', ')
        if (causes !== null && causes !== undefined && causes !== '') {
          createToast(causes)
        } else {
          createToast(`Please contact SysAdmin`)
        }
      })
      .catch((e) => {
        createToast(`Please contact SysAdmin`)
      })
  } else {
    createToast(`Provider configuration successfully registered :D!`)
    window.location.reload()
  }
}

export function listCauses(errorResponse) {
  errorResponse.text().then((content) => {
    JSON.parse(content)
      .errorCauses.filter((error) => error.cause !== '')
      .forEach((error) => createToast(error.cause))
  })
}

function createToast(message) {
  toast.info(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}
