import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

function GuaranteeDepositDetails({ guaranteeDeposits }) {
  if (guaranteeDeposits === undefined) return <></>

  const columns = ['Payment method', 'Days', 'Effective Date']

  return (
    <div className="my-4">
      <h5>Guarantee Deposit:</h5>
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} className="px-0">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {guaranteeDeposits.length > 0 ? (
            guaranteeDeposits.map((guaranteeDeposit) => (
              <GuaranteeDepositRow key={guaranteeDeposit.id} guaranteeDeposit={guaranteeDeposit} />
            ))
          ) : (
            <TableRow>
              <TableCell>No result</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

function GuaranteeDepositRow({ guaranteeDeposit }) {
  const content = [guaranteeDeposit.paymentMethod, guaranteeDeposit.days, guaranteeDeposit.effectiveDate]

  return (
    <TableRow key={`guaranteeDeposit-${guaranteeDeposit.id}`}>
      {content.map((column, index) => (
        <TableCell key={index} className="px-0 capitalize" align="left">
          {column}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default GuaranteeDepositDetails
