import React from 'react'
import { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'

class MultipleItemInput extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  deleteByIndex = (index) => {
    const { onChange, items } = this.props

    let newArray = [...items]
    newArray.splice(index, 1)
    onChange(newArray)
  }

  createNewField = () => {
    const { onChange, items } = this.props
    onChange([...items, { removable: true, key: uuid() }])
  }

  onChange = (item, index) => {
    const { onChange, items } = this.props

    let newArray = items
    newArray[index] = item
    onChange(newArray)
  }

  render() {
    const { title, items } = this.props

    return (
      <div>
        {items != null ? (
          items.map((item, index) => (
            <div className="card-account-register-field center" key={item.key}>
              <div className="row">
                <div className="card-title-position">
                  <h4>{title}</h4>
                </div>
              </div>
              {!item.removable ? (
                <React.Fragment />
              ) : (
                <div
                  className="card-btn-remove"
                  onClick={() => {
                    this.deleteByIndex(index)
                  }}
                >
                  {' '}
                  Remove <FontAwesomeIcon icon={faTrash} />
                </div>
              )}
              <div id="input">
                {React.cloneElement(this.props.children, {
                  item: item,
                  index: index,
                  onChange: (changes) => {
                    this.onChange(changes, index)
                  },
                })}
              </div>
            </div>
          ))
        ) : (
          <React.Fragment />
        )}
        <div className="card-btn-register" onClick={this.createNewField}>
          Add New {title} <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    )
  }
}

export const buildItem = (content, removable) => {
  return { ...content, removable, key: uuid() }
}

export default MultipleItemInput
