import React from 'react'
import { Navigate } from 'react-router-dom'

import payinTransactionsRoutes from './views/payin/payin-transactions/PayinTransactionsRoutes'
import refundRoutes from './views/payin/refunds/RefundRoutes'
import loginRoutes from './views/login/LoginRoutes'
import accountRoutes from './views/accounts/routes'
import reportRoutes from './views/reports/ReportRoutes'
import customerRoutes from './views/customers/CustomerRoutes'
import providerRoutes from './views/providers/ProviderRoutes'
import settlementsRoutes from './views/settlements/SettlementsRoutes'
import cercRoutes from './views/cerc/CercRoutes'
import payoutTransactionsRoutes from './views/payout/PayoutTransactionsRoutes'
import payeeMarchantRoutes from './views/payee-merchant/PayeeMerchantRoutes'

const redirectRoute = [
  {
    path: '/',
    exact: true,
    element: <Navigate replace to="/login/signin" />,
  },
]

const routes = [
  ...payinTransactionsRoutes,
  ...loginRoutes,
  ...refundRoutes,
  ...accountRoutes,
  ...customerRoutes,
  ...providerRoutes,
  ...settlementsRoutes,
  ...reportRoutes,
  ...cercRoutes,
  ...payoutTransactionsRoutes,
  ...payeeMarchantRoutes,
  ...redirectRoute,
]

export default routes
