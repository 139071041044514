export const defineColorStatus = ({
  status,
  approvedList = [],
  deniedList = [],
  failedList = [],
  actionRequiredList = [],
  paidList = [],
  refundedList = [],
}) => {
  if (approvedList.includes(status)) return 'bg-approved'
  if (deniedList.includes(status)) return 'bg-denied'
  if (failedList.includes(status)) return 'bg-failed'
  if (actionRequiredList.includes(status)) return 'bg-processing'
  if (paidList.includes(status)) return 'bg-paid'
  if (refundedList.includes(status)) return 'bg-refunded'
  if (status === null || status === undefined) return ''

  return 'bg-others-status'
}
